.autopill-container {
    display: flex;
    flex-direction: row;
    padding-bottom: 4px;
    margin-right: 4px;
}

.autopill-component {
    box-sizing: border-box;
    border-style: solid;
    border-width: 1px;
    border-right-style: none;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 1px;
    padding-bottom: 3px;
    cursor: pointer;
    border-color: var(--teal);
    color: var(--teal);
    height: 38px;
    line-height: 30px;
}

.autopill2-component:first-of-type {
    border-radius: 30px;
    color: var(--teal);
    border-right-style: solid !important;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    padding-right: 8px;
    padding-left: 16px;
}

/* .autopill-component:first-of-type {
    border-radius: 30px;
    color: #5F5F5F;
    border-right-style: solid !important;
    border-bottom-left-radius: 30px !important;
    border-top-left-radius: 30px !important;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    padding-right: 8px;
    padding-left: 16px;
} */

.autopill-component:not(:first-of-type) {
    color: var(--teal);
    min-width: 100px;
    font-weight: 600;
}

.autopill-component:last-of-type {
    border-radius: 30px;
    border-left-style: none;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    border-right-style: solid;
    border-left-style: solid;
    padding-right: 16px;
    background-color: transparent;
}

.autopill-component:not(:first-of-type) {
    color: var(--teal);
    min-width: 100px;
}

.autopill-component:last-of-type {
    border-radius: 30px;
    border-left-style: none;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    border-right-style: solid;
    border-left-style: solid;
    padding-right: 16px;
    width: 130px;
}

.autopill-component input,
.autopill-component .ms-DatePicker {
    border: none;
    margin: 0px;
    padding: 0px;
    height: 32px;
    font-size: 16px;
    font-weight: 600;
}

.autopill-component select {
    border: none;
    margin: 0px;
    height: 32px;
    font-size: 16px;
}

@media only screen and (max-width: 992px) {

    .autopill-component input:focus,
    .autopill-component select:focus {
        border: none;
        box-shadow: none !important;
    }

    autopill-typeahead {
        position: absolute !important;
    }

    .autopill-component {
        white-space: nowrap;        
        margin-top: -2px;
        padding-top: 0;
        width: 100%;
    }

    .autopill-component input,
    .autopill-component .ms-DatePicker {
        -height: 40px;
        font-size: 15px;
        font-weight: 600;
    }
}