.timeline-container {
    text-align: left;
    position: absolute;
    left: 20px;
    top: 400px;
    z-index: 20;
}

.timeline {
    display: flex;
    flex-direction: row;
    margin-top: -4px;
}

.timeline-icon {
    height: 35px;
    width: 35px;
    font-size: 1.6em;
    padding: 0px;
    margin-left: 4px;
    margin-bottom: 0px;
}

.timeline-content {
   padding: 12px;
   padding-left: 65px;
   background-image: linear-gradient(rgba(51, 204, 153, 1), rgba(51, 204, 153, 1));
   background-size: 2px 100%;
   background-repeat: no-repeat;
   background-position: 19px bottom;    
}

.timeline-header {    
    height: 36px;
    box-sizing: border-box;
    padding-left: 24px;
    color: black;
    line-height: 40px; 
    width: 100%; 
}

.timeline button {
    padding-left: 20px;
    padding-right: 20px;
    float: right;
    border: solid 3px #dadada;
    border-radius: 20px;
    color: #aaaaaa;
    height: 50px;
    width: 120px;
    background-color: white;
    font-weight: bold;
    height: 40px;
    line-height: 30px;
    margin-top: 5px;
    margin-left: 30px;
    font-size: 14px;
}

.timeline button:hover {
    border-color: var(--teal);
    color: var(--teal);
}

.timeline-text {
    display: inline-block;
    width: 160px;
    line-height: 1.3;
}
