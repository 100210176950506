.chat-avatar-circle {
  display: inline-block;
  /* background-color: #0d6efd; */
  background-color: #666;
  border-radius: 50%;
  height: 40px;
  ;
}

.chat-avatar-circle-inner {
  color: white;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  text-decoration: none;
  height: 40px;
  width: 40px;
  font-size: 20px;
}

.circle-robot {
  display: inline-block;
  background-color: #666;
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

.chat-message-container {
  min-height: 40px;
  max-width: 90%;
  padding: 5px;
  line-height: 32px;
}

.chat-message-container-incoming {
  color: #fff;
  background-color: transparent;
  float: right;
}

.chat-message-container-outgoing {
  background-color: rgb(245, 245, 245);
}

.chat-message-enter {
  opacity: 0;
  transition: opacity 700ms ease-in;
}

.chat-message-enter-active {
  opacity: 0;
  transition: opacity 700ms ease-in;
}

.chat-message-exit {
  opacity: 0;
  transition: opacity 700ms ease-out;
}

.chat-message-exit-active {
  opacity: 0;
  transition: opacity 700ms ease-out;
}

.bouncing-loader {
  display: flex;
  justify-content: center;
}

.bouncing-loader>div {
  width: 8px;
  height: 8px;
  margin: 8px 5px;
  border-radius: 50%;
  background-color: #5c5b5b;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    /* transform: translateY(-8px); */
  }
}

.bouncing-loader>div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader>div:nth-child(3) {
  animation-delay: 0.4s;
}

/* .chat-message-container-incoming {
  background-color: transparent;
} */

.chosenanswer {
  background-color: #dc4405;
  margin-right: 10px;
}

.chat-drag-drop {
  min-height: 100px;
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
  background-color: white;
  border-style: dashed;

}

.filepond--drop-label {
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
  background-color: white;
  border-style: dashed;

}
