.confirm-button {
    margin-top: 24px;
    padding-left: 25px;
    padding-right: 25px;
    border: none;
    border-radius: 20px;
    color: white;
    height: 50px;
    background-color: silver;
    height: 40px;
    line-height: 30px;
    font-size: 15px;
}

.box h1 {
    padding-top: 24px;
}