
.aip-download-button {
    border-width: 1px;
    border-style: solid;
    border-color: var(--teal);
    border-radius: 10px;
    box-shadow: none;
    font-style: normal;
    font-size: 14px;
    color: var(--teal);
    background-color: white;
    height: 40px;
    padding-top: 7px;
    padding-left: 10px;
    cursor: pointer;
    width: 100%;
    margin-top: 12px;
}

.aip-download-button span {
    font-weight: bold;
}
.box .aip-status-approved {
    color: var(--teal);
    background-color: var(--chartreuse);
}
.aip-status.aip-status-approved {
    font-family: var(--secondary-font);
    line-height: 1.3;
}
.aip-status-lg {
    font-weight: 700;
    font-family: var(--secondary-font) !important;
    font-size: 26px !important;
    margin-bottom: 0px !important;
}
.aip-validuntil {
    font-weight: 300 !important;
    font-size: 15px !important;
}

.aip-submitted {
    border-color: var(--teal);
    background-color: var(--limestone);
    color: var(--teal);
}

.aip-approved-text-lg {
    font-size: 34px;
    padding-top:7px;
    padding-bottom:7px;
}

.aip-submitted-text-lg {
    font-size: 34px;
    padding-top:7px;
    padding-bottom:7px;
}

.aip-clarifications {
    border-color: var(--error);
    background-color: var(--limestone);
}

.aip-clarifications-text, .aip-clarifications-text-lg {
    color: var(--error);
    font-weight: bold;
}

.aip-clarifications-text-lg {
    font-size: 34px;
    padding-top:7px;
    padding-bottom:7px;
}

.aip-approved {
    border-color: var(--teal);
    background-color: var(--chartreuse);
}

.aip-beprepared {
    border: none;
}

.accordion-button {
    outline: 0px none !important;
    background: none !important;
    font-family: var(--secondary-font);
    padding: 2px;
    color: var(--teal);
}

.accordion-button:focus,.accordion-button:active {
    outline: 0px none #fff !important;
    box-shadow: none;
}
.accordion-button.collapsed {
    color: var(--teal) !important;
}
.accordion-button:not(.collapsed) {
    color: var(--teal) !important;
}
.accordion-button::after, .accordion-button:not(.collapsed)::after {
    margin-top: -5px;
    font-size: 20px;
}
.accordion-button:not(.collapsed)::after {
    
}
.accordion-header {
    background: 'none';
}
.accordion-item {
    border: none;
}

.aip-special-condition {
}

.btn-proceed,.btn-proceed-outline {
    font-size: 15px;
    font-family: var(--secondary-font);
    width: 100%;
    border-color: var(--teal);
    border-width: 1px;
    padding-top: 9px;
    padding-bottom: 9px;
}

.btn-proceed {
    margin-top: 20px;
    font-weight: 400;
}
.btn-proceed:hover{
    background-color: var(--chartreuse) !important;
    -border-color: rgba(51, 204, 153, 0.8) !important;
    border-width: 1px;
    padding-top: 9px;
    padding-bottom: 9px;
}

.btn-proceed-outline {
    margin-top: 10px;
    -color: rgba(51, 204, 153, 1) !important;
    -border-color: rgba(51, 204, 153, 1) !important;
    border-width: 1px;
}
.btn-proceed-outline:hover{
    -background-color: var(--sage) !important;
    -border-color: rgba(51, 204, 153, 0.8) !important;
    color: var(--teal) !important;
    font-weight: 600;
}
