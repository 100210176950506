.address-container {
    padding: 1px;
}

.address-container .form-control {
    border-radius: 0;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    border-bottom: 1px solid silver !important;
    width: 100% !important;
    font-size: 20px !important;
    font-family: var(--secondary-font) !important;
    font-weight: 700 !important;
    -color: #555555 !important;
}

    .address-container .form-select {
        border-radius: 0;
        border-left: 0px;
        border-right: 0px;
        border-top: 0px;
        border-bottom: 1px solid silver !important;
        width: 100% !important;
        font-size: 20px !important;
        font-family: var(--secondary-font) !important;
        font-weight: 700 !important;
        -color: #555555 !important;
    }