.loan-completion-box {
    border-radius: 10px;
    padding: 25px;
    color:rgb(102, 102, 102);
    min-height: 100%;
    border: 1px solid rgb(180, 180, 180);
}

.loan-completion-box h1 {
    font-family: var(--secondary-font);
    font-weight: bold;
    font-style: normal;
    font-size: 32px;
    color: #4A4A4A;
    padding-bottom: 4px;
}

.loan-completion-download-button {
    border-width: 1px;
    border-style: solid;
    border-color: rgba(74, 74, 74, 1);
    border-radius: 10px;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 14px;
    color: #4A4A4A;
    background-color: white;
    height: 40px;
    padding-top: 0px;
    padding-left: 8px;
    cursor: pointer;
    width: 100%;
}

.loan-completion-download-button span {
    font-weight: bold;
}

