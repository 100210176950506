
.draggable-modal {
    display: flex !important;
    align-items: flex-start;
    margin: 0;
}
.custom-modal {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: var(--limestone);
    border-radius: 10px;
    position: absolute;
    z-index: 200;
    top: calc(50% - 120px);
    left: calc(50% - 120px);
}
.modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.dragg-it {
    cursor: move;
    min-height: 30px;
}
.modal-content {
    padding: 15px; 
}
