.prev-button {
        background: url('./prev.png');
        background-repeat: no-repeat;
        background-position: left;
        text-align: left!important;
        padding-left: 42px!important;
        width: 200px;
        border: none;
        height: 36px;
        font-family: "Open Sans", Sans-serif;
        font-size: 18px;
        font-weight: 600;
        background-color: #FFFFFF00;
        color: #A7A7A7;
    }

    .next-button {
        background: url('next.png');
        background-repeat: no-repeat;
        background-position: right;
        text-align: right !important;
        padding-right: 42px !important;
        border: none;
        height: 36px;
        font-family: "Open Sans", Sans-serif;
        font-size: 18px;
        font-weight: 600;
        background-color: #FFFFFF00;
        color: #A7A7A7;
    }

.applicant-header {
    font-weight:bold;
}