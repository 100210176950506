

.pac-container {
    background-color: #fff;
    position: absolute!important;
    z-index: 1000;
    border-radius: 2px;
    border-top: 1px solid #d9d9d9;
    font-family: Arial, sans-serif;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
ul.pac-item {
    margin-bottom: 0.5rem;
    padding-left: 10px;
    padding-right: 10px;
}
.pac-item li {
    cursor: default;
    padding: 0 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-align: left;
    border-top: 1px solid #e6e6e6;
    font-size: 15px;
    padding-left: 10px;
    padding-right: 10px;
}
.pac-item li:hover {
    background-color: #eaeaea
}
.pac-item-selected li,
.pac-item-selected li:hover {
    background-color: #ebf2fe
}
.pac-item-count {
    display:block;
    float: right;
    padding-left: 15px;
}
.pac-matched {
    font-weight: 700
}
.pac-item-query {
    font-size: 13px;
    padding-right: 3px;
    color: #000
}

.autocomplete{
    height: 30px;
    padding-left: 10px;
    border-radius: 4px;
    border: 1px solid rgb(186, 178, 178);
    box-shadow: 0px 0px 12px #EFEFEF;
  }
/*.pac-icon{}
.pac-item:hover	{}
.pac-item-selected{}
.pac-item-query	{}
.pac-matched{}
*/