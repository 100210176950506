.plus-minus-box-container {
    display: flex;
    flex-direction: row;
    padding-bottom: 4px;
    margin-right: 4px;
}

.plus-minus-box-component {
    box-sizing: border-box;
    padding-bottom: 1px;
    cursor: pointer;
    border-color: #D0D0D0;
    color: black;
    height: 50px;
    line-height: 45px;
}

.plus-minus-box-component:first-of-type {
    color: #5F5F5F;
}
.plus-minus-box-component:not(:first-of-type) {
    color: #212529;
    font-weight: 600;
}

.plus-minus-box-component input {
    border: none;
    margin: 0px;
    padding: 0px;
    height: 44px;
    font-size: 15px;
    text-align: center;
    font-weight: 600;
}

.plus-minus-box-component input:focus, .plus-minus-box-component select:focus {
    border: none;
    box-shadow: none !important;
}