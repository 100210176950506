.progress-tile-percentage-xsmall {
    width: 30px !important;
    height: 30px !important;
}

.progress-tile-percentage-small {
    width: 40px !important;
    height: 40px !important;
}

.progress-tile-initials-small {
    font-size: 18px;
    margin-top: -29px;
    margin-left: -1px;
    padding-bottom: 10px;
    text-align: center;
    position: relative;
    z-index: 1;
}

.progress-tile-header-small {
    color: rgba(102, 102, 102, 0.8);
    font-family: ArialMT, Arial;
    font-size: 10px;
    margin-top: 15px;
    text-transform: uppercase;
    overflow: hidden;
    white-space: nowrap;
    padding-bottom: 10px;
}

.progress-tile-percentage-medium {
    width: 75px !important;
    height: 75px !important;
}

.progress-tile-initials-medium {
    font-size: 14px;
    margin-top: -48px;
    padding-bottom: 10px;
    text-align: center;
    position: relative;
    z-index: 1;
}

.progress-tile-header-medium {
    color: rgba(102, 102, 102, 0.8);
    font-family: ArialMT, Arial;
    font-size: 14px;
    margin-top: 30px;
    text-transform: uppercase;
    overflow: hidden;
    white-space: nowrap;
    padding-bottom: 10px;
}

.progress-tile-percentage-large, .progress-tile-percentage-large-done, .progress-tile-percentage-large-notstarted {
    width: 200px;
    height: 150px;
    border-radius: 25px;
    border-width: 0;
    border-style: solid;
    position: relative;
    overflow: hidden;
    padding-top: 15px;
    padding-left: 20px;
    line-height: 1.2;
}

.progress-tile-percentage-large {
    background-color: var(--sage);
}

.progress-tile-percentage-large-done {
    background-color: var(--chartreuse);
}

.progress-tile-percentage-large-notstarted {
    background-color: var(--teal);
}

.progress-tile-initials-large {
    font-size: 60px;
    margin-top: -124px;
    padding-bottom: 10px;
    text-align: center;
    margin-bottom: 70px;
    position: relative;
    z-index: 1;
}

.progress-tile-header-large {
    color: rgba(102, 102, 102, 0.8);
    font-family: ArialMT, Arial;
    font-size: 30px;
    margin-top: 60px;
    text-transform: uppercase;
    overflow: hidden;
    white-space: nowrap;
    padding-bottom: 10px;
}

.progress-tile-selected {
    color: black;
}

.progress-tile-percentage-document {
    width: 75px !important;
    height: 75px !important;
}

.progress-tile-initials-document {
    font-size: 14px;
    margin-top: -48px;
    padding-bottom: 10px;
    position: relative;
    z-index: 1;
}

.progress-tile-header-document {
    color: hsla(0,0%,40%,.8);
    font-family: ArialMT,Arial;
    font-size: 10px;
    margin-top: 15px;
    overflow: hidden;
    padding-bottom: 10px;
    text-transform: uppercase;
    white-space: nowrap;    
}

.progress-tile-clearfix {
    margin-bottom: 0;
}