.pill-container {
    display: flex;
    flex-direction: row;
    padding-bottom: 4px;
    margin-right: 4px;
}

.pill-component {
    box-sizing: border-box;
    border-style: solid;
    border-width: 1px;
    border-right-style: none;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 3px;
    cursor: pointer;
    border-color: var(--teal);
    color: var(--teal);
    height: 38px;
    line-height: 30px;
}

.pill-component:first-of-type {
    border-radius: 30px;
    color: var(--teal);
    border-right-style: solid;
    border-bottom-left-radius: 30px !important;
    border-top-left-radius: 30px !important;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    padding-right: 8px;
    padding-left: 16px;
    text-wrap: nowrap;
}

.pill-component:not(:first-of-type) {
    color: var(--teal);
    min-width: 100px;
    font-weight: 600;
}
.pill-component:last-of-type {
    border-radius: 30px;
    border-left-style: none;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
    border-right-style: solid;
    border-left-style: solid;
    padding-right: 16px;
}
.pill-component:last-of-type:not(:has(.invalid-feedback)) {
    overflow: hidden;
}

.pill-component input, .pill-component .ms-DatePicker {
    border: none;
    margin: 0px;
    padding: 0px;
    height: 30px;
    font-size: 16px;
    text-align: center;
    font-weight: 600;
    background-color: transparent;
    line-height: 30px;
}

.pill-component select {
    border: none;
    margin: 0px 0px 0px 0px;
    height: 32px;
    font-size: 16px;
    font-weight: 600;
    min-width: 100px;
    background-color: transparent;
    line-height: 1;
}

.pill-component .ms-DatePicker {
    padding-top: 6px;
    font-weight: 600;
    border: 0px !important;
    max-width: 100px !important;
}

.pill-component input:focus, .pill-component select:focus {
    border: none;
    box-shadow: none !important;
}

.statusMessage-101 {
    -display: none;
}