:root {
    --limestone: #F5F0ED;
    --teal: #1a3a40;
    --teal-light: #1a3a40AA;
    --teal-extra-light: #1a3a4022;
    --teal2: #275158;
    --sage: #9bc5bb;
    --chartreuse: #d1f891;
    --black: #0F190F;
    --amber: #FE972B;
    --doddlred: #dc4405;
    --travertine: #dbc8bd;
    --error: #FD4674;

    --gray: #4A4A4A;
    --beam-background: rgba(242, 246, 249, 1);

    --primary-font: 'DM Serif';
    --secondary-font: 'Inter',Helvetica,Arial,Lucida,sans-serif;
    --doddltext: var(--teal);

    --background: var(--limestone);
    --primary-font-color: var(--teal);

    --done-back-color: var(--chartreuse);
    --done-font-color: var(--teal);
    --done-border-color: var(--chartreuse);

    --inprogress-back-color: var(--amber);
    --inprogress-back-hover-color: var(--chartreuse);
    --inprogress-font-color: var(--limestone);
    --inprogress-font-hover-color: var(--teal);
    --inprogress-border-color: var(--amber);
    --inprogress-border-hover-color: var(--amber);

    --inprogress-outline-back-color: var(--white);
    --inprogress-outline-back-hover-color: var(--chartreuse);
    --inprogress-outline-font-color: var(--teal);
    --inprogress-outline-font-hover-color: var(--teal);
    --inprogress-outline-border-color: var(--teal);
    --inprogress-outline-border-hover-color: var(--teal);


    --menu-icon-back-color: var(--teal);
    --menu-icon-font-color: var(--limestone);
    --menu-icon-border-color: var(--teal);
    --menu-font-color: var(--teal-light);
    --menu-font-selected-color: var(--teal);
    --menu-icon-selected-color: var(--chartreuse);
    --menu-icon-selected-back-color: var(--sage);


    --bs-body-font-size: 15px;
    --bs-btn-active-bg: var(--teal) !important;
}

body {
    font-family: var(--secondary-font) !important;
    background-color: var(--background);
    color: var(--teal);
    font-size: 16px;
}

.login-container  {
    left: 350px;
    float: left;
    position: relative;
    z-index: 5;
    margin-top: 22px;
}

.auto-margin {
    margin: auto;
}

.box-block-80 {
    min-height: 200px;
}

.portal-page {
    height: fit-content;
    min-height: 100%;
    width: fit-content;
    min-width: 100%;
    margin-left: 0px !important;
    padding-top: 70px;
}
.portal-page-container {
    max-width: 940px;
}
.portal-page.inprogress {
    background-color: var(--sage);
}
.portal-page.complete {
    background-color: var(--chartreuse);
}

.calendly-container {
    min-width:600px;
    height:700px;
}
.required-modal-input {
    border-bottom-color: red !important;
    border-bottom-width: 2px !important;
}

.referral-input {
    border-left: none;
    border-top: none;
    border-right: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.referralpill:last-of-type {
    width: 272px;
}

.referral-email-pill:last-of-type {
    width: 472px;
}

.email-pill {
    min-width: 150px;
}

h2 {
    font-size: 34px !important;
    color: var(--primary-font-color);
}

h2.impact {
    font-weight: 700;
}

h3 {
    font-size: 24px !important;
    font-family: var(--secondary-font);
    color: var(--primary-font-color);
}

h3.s {
    font-size: 20px !important;
    font-family: var(--secondary-font);
    font-weight: 300;
    margin-top: 2px;
}

h3.impact {
    font-weight: 700;
    font-size: 26px !important;
    padding-bottom: 15px;
}

h3.impact-s {
    font-weight: 700;
    font-size: 24px !important;
}

h4 {
    font-size: 16px !important;
    font-weight: 500 !important;
    color: var(--teal);
}

h5 {
    font-size: 20px !important;
    font-family: var(--secondary-font);
}

.ih5 {
    font-size: 1rem;
    font-family: var(--secondary-font);
    padding-left: 5px;
    color: #777777 ;
}

.ih5.impact {
    font-weight: 700 !important;
    line-height: 26px;
}

.ih6 {
    font-size: 0.8rem;
    font-family: var(--secondary-font);
    margin-left: 15px;
}

.ih6.impact {
    font-weight: 700 !important;
    line-height: 24px;
}

.h5 {
    font-size: 1.1rem;
    font-family: var(--secondary-font);
    color: var(--teal) ;
}

.h5.impact {
    font-weight: 700 !important;
}

.h5.light {
    font-weight: 400;
    color: #999999;
}

.h6 {
    font-size: 14px;
    font-family: var(--secondary-font) !important;
    color: #999999;
}

.h6.impact {
    font-weight: 700 !important;
}


/* used in document pills for smaller text descriptions */
h6 {
    color: var(--teal);
    font-family: var(--secondary-font);
    font-size: 14px;
}

h6.filename {
    line-break: anywhere;
}

p {
    margin-bottom: 0.75rem;
    font-size: 18px;
}

p.tiny {
    font-size: 8px;
}
p.s {
    font-size: 12px !important;
}
p.m {
    font-size: 14px !important;
}
p.l {
    font-size: 22px !important;
}
p.italic {
    font-style: italic !important;
}
p.impact {
    font-weight: 700;
}
p.tight {
    padding: 0 !important;
    margin: 0 !important;
}

.v-center {
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.doddl-bg {
    -background-image: url(https://doddl.wpengine.com/wp-content/uploads/2020/12/Irish-Mortgage-Purchasing-Switching.svg?id=270);
    background-repeat: no-repeat;
    height: 100%;
}

.teal-button-group {
    bottom: 0;
    width: 100%;
    position: absolute;
    padding-bottom: 40px;
    padding-top: 40px;
    background-color: var(--teal);
    margin-left: -52px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

.btn:disabled {
    border-color: lightgray !important;
    border-width: 2px !important;
}
.btn.circle {
    border-radius: 50em;
}
.btn.xl.rad-s {
    border-radius: 20px;
}
.btn.bigger.rad-s {
    border-radius: 16px;
}

.btn-done {
    /* color: #fff;
    background-color: #40C999;
    border-color: #40C999; */
    color: white;
    background-color: var(--teal);
    border-color: var(--teal);
    border-width: 1px;
    padding: 9px 30px;
    width: 126px;
    white-space: nowrap;
}

.btn-done:hover {
    color: var(--teal);
    /* background-color: #39a881;
    border-color: #39a881; */
    background-color: var(--chartreuse);
    border-color: var(--teal);
}

.btn-amber {
    color: var(--inprogress-outline-font-color);
    background-color: var(--inprogress-outline-back-color);
    border-color: var(--inprogress-outline-border-color);
    border-width: 1px;
    padding: 9px 20px;
    width: 126px;
    white-space: nowrap;
}

.btn-amber:hover {
    color: var(--inprogress-outline-font-hover-color);
    background-color: var(--inprogress-outline-back-hover-color);
    border-color: var(--inprogress-outline-border-hover-color);
}

.btn-collapse {
    color: #555555;
    background-color: transparent;
    border-color: #919191;
    border-width: 2px;
    padding: 8px 20px;
    width: 126px;
}

.btn-collapse:hover {
    color: #fff;
    background-color: #555555;
    border-color: #555555;
}


.btn-standard,.btn-secondary, .btn-dark, .btn-light {
    color: var(--teal);
    border-color: var(--teal);
    border-width: 1px;
    padding: 4px 20px;
    border-radius: 50em;
}
.btn-standard {
    background-color: var(--sage);
}
.btn-secondary {
    background-color: var(--chartreuse);
}
.btn-dark {
    background-color: var(--teal);
    color: white;
}
.btn-light {
    background-color: var(--limestone);
}
.btn {
    font-size: 14px;
    line-height: 1.4;
}
.btn.big {
    font-size: 14px;
    padding: 8px 25px;
}
.btn.bigger {
    font-size: 16px;
    padding: 10px 40px;
}
.btn.xl {
    font-size: 18px;
    padding: 15px 30px;
}
.btn.bold {
    font-weight: 600;
}

.btn.mini {
    padding:6px 12px;
    margin: 2px 0;
    line-height: 1;
    font-size: 12px;
    min-width: 70px;
    max-height: 26px;
}
.btn.medium {
    min-width: 100px;
}
.btn:hover {
    background-color: var(--chartreuse);
    border-color: var(--teal);
}
.btn-nav {
    font-size: 14px;
    font-family: var(--secondary-font);
    color: var(--teal);
    background-color: var(--limestone);
    border-color: var(--teal);
    border-width: 1px;
    padding: 6px 20px;
    border-radius: 50em;
}
.btn-nav.selected {
    background-color: var(--teal);
    color: white;
}
.btn-nav:hover {
    border-color: var(--teal);
    background-color: var(--chartreuse);
    color: var(--teal);
}

button.btn-close {
    margin-top: 0px !important;
    margin-right: 0px !important;
}

.bubble {
    display: inline-block;
}
.bubble.dark {
    background-color: var(--teal);
    color: white;
}
.bubble.white {
    background-color: white;
    color: var(--teal);
}
.bubble.m {
    height: 50px;
    width: 50px;
    border-radius: 25px;
    padding: 7px;
    font-size: 25px;
}
.bubble.s {
    height: 28px;
    width: 28px;
    border-radius: 15px;
    padding: 4px;
    font-size: 14px;
}

.finance-pill-container {
    display: flex;
    flex-direction: row;
    padding-bottom: 4px;
    background-color: white;
    border-radius: 18px;
    margin-bottom: 5px;
}
.finance-pill {
    box-sizing: border-box;
    border-style: solid;
    border-width: 1px;
    border-color: white;
    background-color: white;
    cursor: pointer;
    color: var(--teal);
    font-size: 16px;
    height: 52px;
    padding-top: 15px;
}
.finance-pill-logo {
    margin-top: -7px;
    opacity: 0.5;
    background-repeat: no-repeat;
    background-size: 40px;
    height: 40px;
    width: 40px;
}

.aib-tile,.boi-tile,.n26-tile,.ptsb-tile,.revolut-tile {
    background-repeat: no-repeat;
    background-size: 40px;
    height: 40px;
    width: 40px;
}
.aib-tile {
    background-image: url('images/aib-tile.png');
}
.boi-tile {
    background-image: url('images/boi-tile.png');
}
.n26-tile {
    background-image: url('images/n26-tile.png');
}
.ptsb-tile {
    background-image: url('images/ptsb-tile.png');
}
.revolut-tile {
    background-image: url('images/revolut-tile.png');
}
.ob-step1, .ob-step2, .ob-step3 {
    display: inline-block;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: visible;
    height: 455px;
    width: 350px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    backdrop-filter: opacity(0.5);
}
.ob-step1 {
    background-image: url('images/ob-step1.png');
    margin-top:-360px;
    margin-left: -550px;
    rotate: -15deg;
}
.ob-step2 {
    background-image: url('images/ob-step2.png');
}
.ob-step3 {
    background-image: url('images/ob-step3.png');
    margin-top:-455px;
    margin-right: -550px;
    rotate: 15deg;
}
.ob-step1-text {
}
.ob-step2-text {
    margin-top: -200px;
}
.ob-step3-text {
}

.beamlogo {
    background-image: url('images/beamlogo.png');
    background-repeat: no-repeat;
    height: 40px;
    margin-top: 30px;
    margin-left: 10px;
    margin-bottom: 20px;
}
.beammargin {
    margin-left: 20px;
}
.beamhero {
    background-image: url('images/beam-image.png');
    background-repeat: no-repeat;
    height: 245px;
    margin: 0px;
    background-size: 263px 252px;
}
.next:hover, .btn-secondary.next {
    background-image: url('images/rightarrow.png');
  }
.next {
    background-image: url('images/rightarrow-c.png');
    background-repeat: no-repeat;
    background-position: calc(100% - 15px) 50%;
    text-align: left !important;
    padding-right: 50px;
}
.next-secondary {
    background-image: url('images/rightarrow.png');
    background-repeat: no-repeat;
    background-position: calc(100% - 15px) 50%;
    text-align: left !important;
}

.btn-pillbutton {
    color: var(--teal);
    background-color: transparent;
    border: none;
    padding: 0 4px;
    border-top-right-radius: 50em;
    border-bottom-right-radius: 50em;
    width: 100%;
    height: 100%;
    font-size: 20px;
}
.btn-pillbutton:hover {
    font-weight: 600;
    -color: var(--chartreuse);
    background-color: var(--sage);
    border: none;
}

.no-min-width {
    min-width: 0 !important;
}

.thick-white-border {
    border-width: 2px;
    border-color: white;
}
.no-left-border {
    border-left-width: 0;
}

.btn-fw, .btn.fw {
    font-size: 15px;
    font-family: var(--secondary-font);
    width: 100%;
    border-width: 1px;
    padding-top: 9px;
    padding-bottom: 9px;
}

.btn-fw.s {
    font-weight: 700;
}


.btn-wide {
    width: 150px !important;
}

.btn-wide200 {
    width: 200px !important;
}
.btn-standard-secondary {
    color: var(--teal);
    background-color: var(--chartreuse);
    border-color: var(--teal);
    border-width: 1px;
    padding: 8px 50px 8px 20px;
    border-radius: 30px;
}

.btn-standard-secondary:hover {
    color: var(--teal);
    background-color: var(--chartreuse);
}
.btn-standard-secondary:focus {
    color: var(--teal);
    background-color: var(--chartreuse);
}

.btn-standard:hover, .btn-secondary:hover, .btn-dark:hover, .btn-standard:focus-visible {
    border-color: var(--teal);
    background-color: var(--chartreuse);
    color: var(--teal);
}

.btn-secondary:hover {
    font-weight: 500;
}

.btn-outline {
    border-color: var(--teal) !important;
    border-width: 1px !important;
    padding: 8px !important;
    min-width: 95px;
}

.btn-outline-secondary {
    border-color: #E6A5BD !important;
    border-width: 2px !important;
    padding: 8px !important;
    min-width: 95px;
}

.btn-outline-narrow {
    border-color: #dc4405 !important;
    border-width: 2px !important;
    padding: 8px !important;
    width: 45px;
}
.btn-outline-small {
    border-color: #dc4405 !important;
    border-width: 2px !important;
    padding: 8px !important;
    border-width: 5px;
    padding: 0 20px !important;
    margin: 5px;
    height: 30px;
}
.btn-outline-small-s {
    border-color: #E6A5BD !important;
    border-width: 2px !important;
    padding: 8px !important;
    border-width: 5px;
    padding: 0 20px !important;
    margin: 5px;
    height: 30px;
    color: black !important;
}
.btn-outline-white {
    border-color: white !important;
    border-width: 2px !important;
    height: 40px;
    width: 40px;
    padding: 0;
    font-size: 22px;
}
.btn-narrow {
    border-width: 2px !important;
    padding: 8px !important;
    width: 45px;
}

.btn-mini {
    border-width: 2px !important;
    padding: 0px !important;
    width: 30px;
}

.btn-standard-transparent {
    color: var(--teal);
    background-color: transparent;
    border-color: transparent;
    border-width: 10px;
    padding: 0 20px;
    border-radius: 50em;
}

.btn-standard-transparent:hover {
    background-color: var(--chartreuse);
    border-width: 10px;
    padding: 0 20px;
}

.btn-gray-outline {
    background-color: transparent;
    border-color: var(--teal);
    border-width: 1px;
    padding: 10px 0;
    border-radius: 50em;
}

.btn-gray-outline:hover {
    background-color: var(--limestone);
}

.btn-green-outline {
    color: rgba(51, 204, 153, 1);
    background-color: transparent;
    border-color: rgba(51, 204, 153, 1);
    border-width: 2px;
    border-style: solid;
    padding: 10px 0 10px 0;
    border-radius: 50em;
}

.btn-green-outline:hover {
    background-color: rgba(51, 204, 153, 1);
    color: white;
}


.fakebtn-gray-outline {
    background-color: transparent;
    border-color: var(--teal);
    border-width: 1px;
    border-style: solid;
    padding: 8px 0 10px 0;
    border-radius: 50em;
}

.fakebtn-teal-outline {
    color: var(--teal);
    background-color: transparent;
    border-color: var(--teal);
    border-width: 2px;
    border-style: solid;
    padding: 10px 0 10px 0;
    border-radius: 50em;
}

.nowrap {
    white-space: nowrap;
}
.w-180 {
    width: 180px;
}
.w-360 {
    width: 360px;
}
.social {
    cursor: pointer;
}
.social:hover {
    color: #777;
}

/* .btn-standard-transparent-secondary {
    color: #E6A5BD;
    background-color: transparent;
    border-color: transparent;
    border-width: 10px;
    padding: 0 20px;
} */

.btn-small {
    color: #fff;
    background-color: #dc4405;
    border-color: #dc4405;
    border-width: 5px;
    padding: 0 20px;
    margin: 5px;
    height: 30px;
}

    .btn-small:hover {
        color: #fff;
        background-color: #c33c04;
        border-color: #c33c04;
        border-width: 5px;
        padding: 0 20px;
    }

.btn-small-transparent {
    color: #dc4405;
    background-color: transparent;
    border-color: transparent;
    border-width: 5px;
    padding: 0 20px;
    margin: 5px;
    height: 30px;
}

    .btn-small-transparent:hover {
        color: #fff;
        background-color: #dc4405;
        border-color: #dc4405;
        border-width: 5px;
        padding: 0 20px;
    }

.btn-cancel, .btn-cancel:active, .btn-cancel:focus, .btn-cancel:focus-visible {
    color: #fff;
    background-color: #cac7c7 !important;
    border-color: #cac7c7 !important;
    border-width: 10px;
    padding: 0 20px;
}

    .btn-cancel:hover {
        color: #fff;
        background-color: #cac7c7;
        border-color: #cac7c7;
        border-width: 10px;
        padding: 0 20px;
    }

.ic-button {
    cursor: pointer;
    position:fixed;
    top:24px;
    right: 15px;
    font-size: 17px;
    color: var(--teal);
    z-index: 10;
}
.accordion {
    padding: 10px 10px 6px 0px;
    background-color: transparent;
}
.accordion-item {
    background-color: transparent;
}
.special-conditions {
    font-size: 14px;
    text-align: left;
    padding: 0 0 0 20px;
}
.accordion-button::after{
    -background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20448%20512%22%3E%3C%21--%21%20Font%20Awesome%20Pro%206.3.0%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%20%28Commercial%20License%29%20Copyright%202023%20Fonticons%2C%20Inc.%20--%3E%3Cpath%20d%3D%22M201.4%20406.6c12.5%2012.5%2032.8%2012.5%2045.3%200l192-192c12.5-12.5%2012.5-32.8%200-45.3s-32.8-12.5-45.3%200L224%20338.7%2054.6%20169.4c-12.5-12.5-32.8-12.5-45.3%200s-12.5%2032.8%200%2045.3l192%20192z%22%2F%3E%3C%2Fsvg%3E");
    background-image: none;
    font-family: 'FontAwesome';
    content: "\f078";
    font-size: 30px;
    margin-top: -20px;
    margin-left: -10px;
    transition: none;
}
.accordion-button:not(.collapsed)  {
    background-color: #eee;
    border-color: #dc4405;
    border-style: solid;
    color: #bbbbbb !important;
    box-shadow: none;
}
.accordion-button:not(.collapsed)::after {
    -background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath fill-rule='evenodd' stroke-width='5' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    -background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20448%20512%22%3E%3C%21--%21%20Font%20Awesome%20Pro%206.3.0%20by%20%40fontawesome%20-%20https%3A%2F%2Ffontawesome.com%20License%20-%20https%3A%2F%2Ffontawesome.com%2Flicense%20%28Commercial%20License%29%20Copyright%202023%20Fonticons%2C%20Inc.%20--%3E%3Cpath%20d%3D%22M201.4%20406.6c12.5%2012.5%2032.8%2012.5%2045.3%200l192-192c12.5-12.5%2012.5-32.8%200-45.3s-32.8-12.5-45.3%200L224%20338.7%2054.6%20169.4c-12.5-12.5-32.8-12.5-45.3%200s-12.5%2032.8%200%2045.3l192%20192z%22%2F%3E%3C%2Fsvg%3E");
    background-image: none;
    font-family: 'FontAwesome';
    content: "\f078";
    font-size: 20px;
    font-weight: 900;
    margin-top: 12px;
}

  .accordion-button.collapsed  {
    /* -background-color: #fef6f3;
    -background-color: #fdc3ab;
    -background-color: #dc4405;
    -background-color: #EFB301;
    -background-color: #E6A5BD;
    -background-color: #ec5415; */
    background-color: #eee;
    border-color: #dc4405;
    border-style: solid;
    color: #bbbbbb !important;
}
/* .accordion-button.collapsed::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  } */


.accordion-button:focus {
    border-color: #dc4405;
    --bs-accordion-btn-focus-border-color: #dc4405;
}

.page-title {
    padding: 20px 20px 10px 20px;
    animation: fadeIn 2s;
}

.page-title > h1 {
    font-size: 50px;
    font-weight: 900;
    font-family: var(--primary-font);
    color: var(--teal);
    text-align: center;
}

h1 {
    font-family: var(--primary-font);
    color: var(--primary-font-color);
    font-weight: 700;
}

h1.light {
    font-weight: 500 !important;
    opacity: 0.5;
}

h1.xlight {
    font-weight: 300 !important;
}

h1.m {
    font-size: 32px !important;
}

h1.s {
    font-size: 22px !important;
}

h2.light {
    font-size: 30px !important;
    font-weight: 400 !important;
}
h2.xlight {
    font-size: 30px !important;
    font-weight: 300 !important;
}
h1.notstarted {
    color: white;
}

.sub-title-box {
    padding-bottom: 30px;
    animation: fadeIn 2s;
}

.mobile {
    display: none;
}

.subtitle {
    font-size: 18px;
    font-family: var(--secondary-font);
    margin-bottom: '25px';
    padding-bottom: 30px;
}

.render-linebreaks {
    white-space: pre-line;
}
@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.notcopied {
    opacity: 0;
    display: none;
}
.copied, .notcopied {
    margin-left: -62px;
    margin-right: 30px;
    color: white;
    background-color: #dc4405;
    height: 32px;
    width: 23px;
}

.copied {
    animation: fadeOut 1.5s;
    display: block;
}

@keyframes fadeOut {
    0% { opacity: 1; }
    50% { opacity: 1; }
    100% { opacity: 0; }
}

.dot {
    font-size: 32px;
    line-height: normal;
}

.subtext {
    font-size: 18px !important;
    font-weight: 400 !important;
    color: var(--primary-font-color);
    text-align: center;
}

.doddltext {
    -color: var(--doddltext);

}

.doddl-gray {
    color: var(--teal);
}

.doddl-lgray {
    color: #aaaaaa;
}

.next-button, .prev-button {
    color: #dc4405;
}

.main-footer {
    position: fixed;
    bottom: 0;
    height: 45px;
    width: 100%;
    background-color: #dc4405;
    color: #fff;
    z-index: 100;
}
/*
ul {
    list-style-type: none !important;
    margin: 0;
    padding: 0;
} */

ul li {
    list-style-type: none !important;
}

.list-group-item {
    margin: 15px !important;
    border-radius: 8px !important;
    box-shadow: 0px 0px 5px #bbb !important;
}

.card {
    margin: 5px !important;
    border-radius: 14px !important;
    border-color: var(--teal);
    background-color: var(--limestone);
    max-width: 400px;
}


.card-footer {
    border-top: 0px !important;
}

input:focus, textarea:focus, select:focus {
    border-color: #dc4405 !important;
    box-shadow: 0px 0px 5px #dc4405 !important;
}

.form-label {
    line-height: 22px;
    text-align: left;
    font-size: 15px !important;
    padding-bottom: 5px !important;
    font-weight: 600 !important;
}

.mb-1 {
    padding-right: 40px;
}

.form-date {
    border-radius: 0;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    border-bottom: 1px solid silver !important;
    padding: 5px 0px 5px 10px;
    width: 100% !important;
    font-size: 20px !important;
    font-family: var(--secondary-font) !important;
    font-weight: 700 !important;
}

.form-control, .form-select, .form-date {
    background-color: transparent;
}
.modal-control > .form-select {
    line-height: 2 !important;
    color: rgb(0, 0, 0, 0.5) !important;
}
.modal-control {
    background-size: 32px 24px;
    background-blend-mode:difference;
    margin-bottom: 1rem;
    color: rgb(0, 0, 0, 0.5) !important;
}
.modal-control > .react-date-picker__inputGroup {
    opacity: 0.5;
}

.form-date .react-date-picker__inputGroup__input {
    color: rgb(0, 0, 0, 0.5) !important;
}

.form-control.is-invalid {
    padding-right: 20px;
    background-position: right;
}

.invalid-feedback {
    margin-top: -2px;
}

.pac-item {
    font-size: 15px !important;
}

.pac-container {
    width: inherit;
    max-height: 100%;
    overflow-y: auto;
}

/* .page-sub-title > h1 {
    font-size: 36px !important;
} */

.sub-label {
    margin-top: -8px;
    font-size: 12px;
    font-style: italic;
}

.heatmap {
    margin: 0px !important;
}

.heatmap-col {
    padding: 0px !important;
    margin-right: 2px !important;
    margin-bottom: 1px !important;
}

.heatmap-row {
    margin-bottom: 2px !important;
}

.heatmap-box {
    z-index: 2;
    position: relative;
    height: 100% !important;
    min-height: 72px;
    font-size: 10px;
    vertical-align: middle;
    border-radius: 2px;
}

.heatmap-box1 {
    background-color: #b2d3c2 !important;
}

.heatmap-box2 {
    background-color: #b2c3d3 !important;
}

.heatmap-star-container {
    z-index: 1;
}

.heatmap-star {
    margin-top: -51px;
    position: absolute;
    margin-left: -17px;
    font-size: 34px;
    color: gold;
}

.heatmap-perc-100 {
    color: transparent;
    background-color: transparent !important;
}

.heatmap-wrap-col-start {
    width: 10px;
    padding-left: 11px;
    padding-right: 10px;
}

.heatmap-wrap-col-end {
    padding-left: 10px;
    padding-right: 10px;
}

.btn-card-right {
    margin-left: -65px
}

.standard-nav {
    /* background-color: #4a4a4a; */
    background-color: transparent;
    height: 100%;
    position: fixed;
}

.nav-svg {
    width: 2em;
    color: #7a7a7a;
}

.nav-item {
    width: 100%;
}

.nav-link {
    color: var(--teal);
    margin-bottom: 0px;
    padding-top: 0px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.sidenav {
    border-right-style: solid;
    border-right-width: 1px;
    border-right-color: #DDDDDD;
    padding-left: 22px;
    padding-right: 10px;
    z-index: 10;
    height: 700px;
    /* width: 98px; */
    position: relative;
    background-color: var(--background);
}

.sidenav-collapsed {
    border-right: none;
    max-width: 220px;
}

.applicantnav {
    border-right-style: solid;
    border-right-width: 3px;
    border-right-color: #eeeeee;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--background);
    z-index: 1;
    font-family: "var(--secondary-font)", "ArialMT", Arial;
    font-weight: 400;
    white-space: nowrap;
    height: 700px;
    min-width: 340px;
    position:absolute;
    top: 40px;
    left: 100px;
}

.applicantnav-submenu,.applicantnav-submenu2 {
    height: 32px;
    margin-top: 10px;
    margin-bottom: 15px;
}
.applicantnav-submenu {
    width: 100%;
    font-weight: 700;
}
.applicantnav-submenu.nohover:hover {
    color: var(--teal)!important;
    background-color: var(--limestone)!important;
    cursor:default!important;
}
.applicantnav-submenu2 {
    margin-left: 38px;
    width: calc(100% - 38px);
    font-weight: 500;
}

.nav-item-lg {
    font-size: 14px;
    padding: 9px 8px 9px 20px;
    flex: 0 0 auto;
    width: 100%;
    color: var(--teal);
    border: 1px solid var(--teal);
    border-radius: 50em;
    text-align: start;
}
.nav-item-lg.xp {
    padding-top: 10px;
    padding-bottom: 10px;
}
.nav-item-lg.dark {
    background-color: var(--teal);
}
.nav-item-lg.light {
    background-color: var(--limestone);
}

.nav-item-div {
    font-size: 14px;
    font-family: var(--secondary-font);
    padding: 0px 6px 0px 20px;
}

.nav-text {
    padding-top: 6px !important;
    flex: 0 0 auto;
    width: calc(100% - 90px);
    color: var(--teal);
    background-color: var(--limestone);
    border-left: 1px solid var(--teal);
    border-top: 1px solid var(--teal);
    border-bottom: 1px solid var(--teal);
    border-top-left-radius: 50em;
    border-bottom-left-radius: 50em;
}
.nav-text.header {
    background-color: var(--teal);
    color: white;
    font-weight: 700
}
.nav-text.selected {
    background-color: white;
}
.nav-text:hover:not(.nohover){
    border-color: var(--teal);
    background-color: var(--chartreuse);
    color: var(--teal);
}

.nav-title {
    padding-top: 6px !important;
    flex: 0 0 auto;
    height: 38px;
    width: 100%;
    background-color: var(--teal);
    color: white;
    font-weight: 700;
    border: 1px solid var(--teal);
    border-radius: 50em;
}

.nav-btncol,.nav-perc,.nav-perc-teal {
    flex: 0 0 auto;
    width: 90px;
    color: var(--teal);
    border-top-right-radius: 50em;
    border-bottom-right-radius: 50em;
    min-height: 38px;
}
.nav-perc,.nav-perc-teal {
    padding-top: 6px !important;
    padding-bottom: 6px;
    border: 1px solid var(--teal);
}
.nav-btncol {
    padding-top: 3px !important;
    padding-bottom: 3px;
    border-top: 1px solid var(--teal);
    border-right: 1px solid var(--teal);
    border-bottom: 1px solid var(--teal);
    border-left: 0;
}
.nav-btncol.selected {
    background-color: white;
}
.nav-perc {
    background-color: var(--limestone);
}
.nav-perc-teal {
    background-color: var(--teal);
}
.nav-perc.inprogress {
    background-color: var(--sage);
}
.nav-perc.completed {
    background-color: var(--chartreuse);
}


.applicantnav-sublink {
    border: none;
    padding-top: 8px;
    padding-bottom: 8px;
}

.profile-group {
    padding-bottom: 10px;
    width: 100%;
}

.main-nav-item-text {
    padding-top: 6px;
    font-family: var(--secondary-font);
    font-weight: 500;
    margin-left: 42px;
    margin-top: -27px;
    min-width: 130px;
    text-align: start;
}


.main-nav-item-perc {
    padding-top: 6px;
    padding-left: 10px;
    margin-top: -26px;
    margin-left: 110px;
    font-family: var(--secondary-font);
    font-weight: 500;
    min-width: 130px;
    text-align: start;
    color: #7a7a7a;
}

.v-rule {
    margin-top: -21px;
    margin-left: 105px;
    color: #7a7a7a;
}


.i-sm {
    font-size: 0.7rem;
    padding-top: 5px;
}


.separator {
    color: #4a4a4a;
}

.nav-icon {
    width: 15px;
}

.nav-icon-sub {
    width: 15px;
    padding-right: 20px;
}

.nav-percentage {
    width: 50px !important;
    height: 50px !important;
}

.nav-initials {
    font-size: 14px;
    margin-top: -35px;
    padding-bottom: 10px;
}

.nav-header {
    color: rgba(102, 102, 102, 0.8);
    font-family: ArialMT, Arial;
    font-size: 10px;
    margin-top: 15px;
    text-transform: uppercase;
    overflow: hidden;
    white-space: nowrap;
    padding-bottom: 10px;
}

.react-calendar {
    width: 300px;
    font-family: "Segoe UI";
}

/* DatePicker styling */
.react-date-picker__wrapper {
    border: none;
}

.react-calendar__navigation {
    margin-bottom: 0.7em;
}

.react-calendar__navigation__label {
    font-size: 15px;
    font-weight: 500;
}

.react-calendar__month-view__weekdays__weekday {
    background-color: '#eeeeee';
}

.react-calendar__tile {
    line-height: 12px;
}

.react-date-picker__inputGroup__input:focus {
    border: 0px solid !important;
    outline-color: #dddddd;
    outline-width: 2px;
    background-color: #DDDDDD;
}

.react-date-picker__inputGroup__input::selection {
    border: 0px solid !important;
    background-color: #DDDDDD !important;
}

.react-date-picker__inputGroup__input:active {
    border: 0px solid !important;
    background-color: #DDDDDD !important;
}

.react-date-picker__inputGroup__input:focus {
    border: none;
    background-color: #DDDDDD;
}

.react-calendar__navigation button {
    min-width: 40px;
}

.react-calendar__year-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__century-view .react-calendar__tile {
    padding: 1.5em 0.5em;
}

abbr[title] {
    -webkit-text-decoration: none;
    cursor: help;
    text-decoration-skip-ink: none;
    text-decoration: none;
}


.ms-DatePicker-mod {
    padding-top: 8px;
}

/* DatePicker Calender - Not sure how to hide the little date calendar on the date picker, so let's just style it away... */
.ms-DatePicker-event--without-label {
    display: none;
}

.react-calendar {
    border-width: 0px;
    font-size: 14px;
    border-color: transparent;
    box-sizing: border-box;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.133) 0px 6.4px 14.4px 0px, rgba(0, 0, 0, 0.11) 0px 1.2px 3.6px 0px;
    outline: transparent;
}

.react-calendar__navigation__label {
    font-size: 15px;
}

.portalbrandlogo {
    width: 150px;
    margin-top: -11px;
    padding-bottom: 7px;
}

.all-content-center {
    align-items: center;
    align-content: center;
}
.md-p-se-3 {
    padding-left: 3rem!important;
    padding-right: 3rem!important;
}

.boxless {
    border-color: transparent !important;
    box-shadow: none !important;
}

.CircularProgressbar-text {
    font-weight: 700;
}

.progresscomplete {
    -color: var(--primary-font-color);
    font-weight: 600;
    -background-color: var(--chartreuse);
}

.progresscomplete.notstarted {
    color: var(--sage);
}

.progresscomplete.inprogress {
    color: white;
}

.progresscomplete-bg {
    background-color: var(--teal) !important;
}

.progressinfo {
    color: #153BA7
}

.inprogress {
    color: var(--teal);
}

.inprogress-bg {
    background-color: var(--sage) !important;
}

.approved {
    background-color: var(--chartreuse);
}

.progresswarning {
    color: red;
}

.lightcolor {
    color: silver;
}

.highlightpill {
    border-width: 2px !important;
    box-shadow: 0px 0px 2px 1px var(--sage);
}
.greyedpill {
    display: none;
    opacity: 0.2;
}

.greyedcategory {
    display: none;
    color: var(--teal-extra-light) !important;
    background-color: var(--limestone);
}
.highlightcategory {
    box-shadow: 0px 0px 2px 1px var(--teal);
}

.percentage-initials {
    margin-top: -128px;
    margin-bottom: 80px;
    font-size: 3em;
    font-weight: 700;
    position: relative;
    z-index: 1;
}

.shadow-tile {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    width: 170px;
    height: 170px;
    border-radius: 85px;
    border-width: 7px;
    border-style: solid;
    border-color: white;
}

.mortgage-detail-div {
    min-height: 100px;
}

.animate-up-1 {
    animation: moveUp1 1s ease-in-out;
}
.animate-up-2 {
    animation: moveUp2 1.2s ease-in-out;
}
.animate-up-3 {
    animation: moveUp3 1.4s ease-in-out;
}
.animate-up-4 {
    animation: moveUp4 1.6s ease-in-out;
}

.animate-noscroll {
    animation: noScroll 4s;
}

@keyframes moveUp1 {
    from {
      transform: translateY(40rem);
    }
    to {
        transform: translateY(0rem);
    }
}

@keyframes moveUp2 {
    from {
      transform: translateY(50rem);
    }
    to {
      transform: translateY(0rem);
    }
}

@keyframes moveUp3 {
    from {
      transform: translateY(60rem);
    }
    to {
      transform: translateY(0rem);
    }
}

@keyframes moveUp4 {
    from {
      transform: translateY(70rem);
    }
    to {
      transform: translateY(0rem);
    }
}

@keyframes noScroll {
    from {
        overflow: hidden;
    }
    to {
    }
}

.percentage-step {
    right: -10px;
    position: absolute;
    width: 50px;
    height: 50px;
    background-color: gray;
    color: white;
    padding: 8px 8px 0px 8px;
    border-radius: 25px;
    font-family: var(--secondary-font);
    font-size: 24px;
}

.calendar {
    width: auto !important;
}

.fw-700 {
    font-weight: 700;
}

[contenteditable] {
    outline: 0px solid transparent;
}

.btn-menu {
    position: absolute;
    font-size: 40px;
    left: 5px;
}

.btn-menu:hover {
    position: absolute;
    font-size: 40px;
    left: 5px;
    background-color: transparent;
    border-color: transparent;
    color: #c33c04;
}

.pointer {
    cursor: pointer;
}

.link {
    cursor: pointer;
    text-decoration: underline;
}

.transaction-comment {
    background-color: var(--sage);
    border-radius: 5px;
    padding: 5px;
    margin-bottom: 5px;
}

.nopointer {
    cursor: default !important;
    pointer-events: none;
}

.documentlist {
    margin-top: -18px;
}

.document-upload {
    border-right: 1px solid var(--travertine);
}

.postit {
    min-height: 200px;
    background-color: #F5D471;
    box-shadow: 2px 2px 5px 0px rgb(138 138 138 / 70%);
    padding: 15px 5px 5px 15px;
}

.tape {
    width: 50px;
    height: 25px;
    opacity: 0.7;
    background: #850e0e;
    margin: 0 auto;
    margin-bottom: -10px;
    transform: skew(-25deg);
    -o-transform: skew(-25deg);
    -moz-transform: skew(-25deg);
    -webkit-transform: skew(-7deg);
}

.postititem {
    font-size: 1.2em;
    font-family: Caveat;
    padding-right: 2px;
    cursor: pointer;
}

.postititemdone {
    text-decoration: line-through;
    opacity: 0.4;
}

.postititem-approved {
    color: #028A0F
}

.exclamation {
    color: darkred;
    background-color: transparent;
    padding-left: 1px;
}

.btn-outline-doddl {
    margin-right: 10px;
    margin-bottom: 10px;
    border-width: 2px;
    --bs-btn-color: #dc4405;
    --bs-btn-border-color: #dc4405;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #dc4405;
    --bs-btn-hover-border-color: #dc4405;
    --bs-btn-focus-shadow-rgb: 220,53,69;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #dc4405;
    --bs-btn-active-border-color: #dc4405;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #dc4405;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #dc4405;
    --bs-gradient: none;
}

.doddl-nav {
    position: absolute;
    -font-size: 24px;
    color: #676767;
    padding-top: 100px;
    align-items: baseline;
    background-color: var(--limestone);
    height: fit-content;
    min-height: 100%;
    border-right: 1px solid var(--travertine);
    padding-left: 15px;
    padding-right: 15px;
}

.doddl-sub-nav {
    margin: 6px 13px 12px;
    min-height: 40px;
    min-width: 292px;
    border-left-color: #dadada;
    border-width: 8px;
    border-left-style: dotted;
    padding-left: 6px;
}

.hidden-drag-drop {
    display: none;
}

.show-drag-drop {
    display: inline;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.pull-up-3 {
    margin-top: -3px;
}

.ms-DetailsRow-fields {
    font-size: 14px;
}

.pill-row {
    padding-left: 50px;
    padding-right: 50px;
}

.tile-row {
    padding-left: 150px;
    padding-right: 150px;
}

.max-w-940 {
    max-width: 940px;
}

.max-w-wide {
    max-width: 1440px;
}

.max-w-345 {
    max-width: 345px;
}

.pill-document {
    border-color: var(--teal);
    border-style: solid;
    border-width: 2px;
    border-radius: 30px;
    padding-right: 20px;
}

.document-file {
    border-color: var(--teal);
    border-style: solid;
    border-width: 1px;
    border-radius: 10px;
    padding: 10px 10px 5px 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.document-file.complete {
    background-color: var(--chartreuse);
}


.file-icon {
    color: var(--sage);
    font-size: 18px;
}


.pill-col {
    height: 100%;
    min-height: 800px;
    min-width: 275px;
}

.pill-buttons {
    justify-content: center;
    padding-bottom: 20px;
}

.pill-buttons-right {
    justify-content: right;
    padding-bottom: 20px;
}

.pill-icon,.pill-icon-app {
    float: right;
    position: absolute;
    color: var(--sage);
}
.pill-icon {
    margin-left: -27px;
    background-color: var(--limestone);
}
.pill-icon.needsattention {
    margin-left: 0;
}

.pill-big {
    font-size: 22px;
}

.pill-header {
    padding-left: 5px;
    cursor: pointer;
    line-height: 1.3;
}
.pill-category {
    font-family: ArialMT, Arial;
    font-size: 10px;
    -margin-top: 5px;
    text-transform: uppercase;
    overflow: hidden;
    white-space: nowrap;
    min-height: 10px;
}

.pill-text {
    font-family: var(--secondary-font);
    color: var(--teal);
    font-size: 16px;
    padding-top: 5px;
    padding-bottom: 5px;
    overflow: hidden;
    white-space: nowrap;
}

.overflow {
    overflow: visible;
}

.pill-count,.pill-count-float {
    font-family: var(--secondary-font);
    font-size: 14px;
    font-weight: 650;
    border-style: solid;
    border-width: 3px;
    border-radius: 50px;
    text-align: center;
    vertical-align: middle;
    padding-top: 0px;
    width: 23px;
    height: 23px;
    display: inline-block;
}

.pill-count-float {
    position: absolute;
    margin-left: -35px;
    background-color: white;
}

.pill-count-col {
    padding-top: 4px;
    padding-left: 10px;
    padding-right: 0;
}

.pill-form {
    min-height: 200px;
}

.pill-stack {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
}

.pill-flex {
    border-style: solid;
    border-width: 2px;
    border-radius: 30px;
    padding-left: 12px;
    padding-bottom: 3px;
    padding-right: 12px;
    cursor: pointer;
}

.pill-input {
    border-top-width: 2px;
    border-bottom-width: 2px;
    border-right-width: 2px;
    border-left-width: 0px;
    font-weight: 600;
    width: auto;

}

/* .pill-input-200 {
    width: 200px;
}
 */
.pill-input-200 > .ms-DatePicker {
    width: 138px !important;
    font-weight: 600;
}

.progresscontainer{
    position: relative;
    border: 1px solid var(--teal);
    border-radius: 15px;
    overflow: hidden;
}
.progressfile{
    height: 30px;
    width: 100%;
    margin: 0;
    font-size: 15px;
}

/* .pill-input >  */
.input-group-text {
    background-color: transparent;
    border: none;
    color: #5F5F5F;
    font-size: 14px;
}

.pill-input > .form-control, .pill-input > .form-select, .pill-input > .form-date {
    /* border-right-width: 2px;
    border-top-width: 2px;
    border-bottom-width: 2px;
    border-left-width: 0px; */
    border: none;
    font-weight: 600;
}

.pill-input-div {
    display:flex;
    visibility: hidden;
    margin-top: -55px;
}

.pill-missing {
    border-color: var(--teal) !important;
    border-width: 2px;
    color: var(--teal) !important;
    font-weight: 500;
    background-color: white !important;
    min-height: 39px;
}

.notstarted:not(.notstarted) {
    color: var(--teal);
    background-color: white;
}


.needsattention {
    color: var(--error);
}


.error,.error-card {
    border-color: var(--error);
    color: var(--error) !important;
}
.error-card {
    border-width: 3px;
}

.done {
    border-color: var !important;
    color: var(--teal) !important;
}

i.inprogress {
    color: var(--sage) !important;
}
i.done {
    color: var(--teal) !important;
}

/* some styles to support input pill */
.form-control-span {
    display: flex;
    border: none;
    font-weight: 600;
    margin-left: 12px;
    margin-right: 12px;
    min-width: 20px;
}

.input-wrap {
    white-space: nowrap;
}

.span-label {
    font-weight: 700;
}

.showhide-label {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-style: dotted;
    color: #aaaaaa;
    font-size: 12px;
}

/* .btn-showhide {
    text-decoration: underline;
    text-decoration-style: dashed;
} */

.pl-5-imp {
    padding-left: 60px !important;
}

.control-icon {
    padding-right: 10px;
    /* float: left; */
    position: absolute;
    margin-top: 82px;
    z-index: 40;
    font-size: 26px;
    color:rgb(153, 153, 153);
}

.categoryselected {
    border-bottom-color: #dc4405;
    border-bottom-width: 5px;
    border-bottom-style: solid;
    margin-bottom: 8px !important;
}

.progresstile {
    padding-bottom: 0px;
}

.toggle-box {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 30px;
}

.justify-center {
    justify-content: center;
    justify-items: center;
    text-align: center;
    align-items: center;
    align-content: center;
}

.modal-content {
    border-radius: 20px;
    background-color: var(--limestone);
}

.modal-body {
    padding-top: 20px;
    padding-bottom: 20px;
}

.financial-modal-content {
    background: transparent !important;
    border: none;
}

.modal-footer {
    padding-right: 25px;
}

.bump-right {
    margin-left: 380px;
    width: 70%;
    -animation: movePageRight 0.5s ease-in-out;
}

.bump-right-sm {
    margin-left: 300px;
    width: 70%;
    -animation: movePageRight 0.5s ease-in-out;
}

.bump-right-profile {
    margin-left: 30px !important;
}

@keyframes movePageRight {
    from {
      transform: translateX(-8rem);
    }
    to {
        transform: translateX(0rem);
    }
}

.box {
    border-radius: 10px;
    padding: 20px;
    min-height: 100%;
    max-width: 400px;
    --min-width: 300px;
    border: 1px solid var(--travertine);
}


.box h1 {
    font-weight: bold;
    font-style: normal;
    font-size: 24px;
    padding-bottom: 4px;
}

.box p {
    font-style: normal;
    font-size: 14px;
    margin-bottom: 8px;
}

.box p span {
    font-size: 14px;
    font-weight:bold;
}

.box .aip-status {
    text-align: center;
    font-weight: bold;
    font-size: 16PX;
}

.cta {
    padding-top: 10px;
    padding-bottom: 10px;
    min-height: 86px !important;
    min-width: 250px;
    text-align: start;
    justify-content: start;
}

.cta-exp {
    min-height: 276px !important;
}


.cta-div {
    height: 100px;
    vertical-align: middle;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.teal {
    background-color: var(--teal) !important;
    color: white !important;
    border: none;
}

.travertine {
    background-color: var(--travertine) !important;
    color: var(--teal) !important;
    border: none;
}

.sage {
    background-color: var(--sage) !important;
    color: var(--teal) !important;
    border: none;
}


.box h1 {
    font-family: 'DM Serif';
    font-weight: bold;
    font-style: normal;
    font-size: 24px;
    color: var(--teal);
    padding-bottom: 4px;
}

.box .cta-text {
    font-family: var(--secondary-font);
    font-size: 22px;
    font-weight: 600;
    color: var(--teal) !important;
}

.teal .cta-text {
    color: var(--chartreuse) !important;
}

.box p {
    font-family:'Open Sans';
    font-style: normal;
    font-size: 14px;
    -color:rgb(102, 102, 102);
    margin-bottom: 8px;
}

.modal-header {
    border-bottom-style: none;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
}

.modal-footer {
    border-top-style: none;
}

.modal-backdrop {
    --bs-backdrop-opacity: .75;
}

.navbar-brand {
    margin-left: 30px;
}

.mr-34 {
    margin-right: 34px;
}

.field-107 {
    font-weight: 600;
    height: 32px !important;

}

.field-120 {
    height: 32px !important;
}

.maxwidth300,.maxw300 {
    max-width: 300px;
}
.minwidth270,.minw270 {
    min-width: 270px;
}

/*
Need to think about these stylings... unfortunately, modals have a Model.Content that isn't exposed but drives the background colour, and we want to make that transparent!!

.finance-modal {
    background-color: #000000;
    ---bs-backdrop-opacity: .75;
}

*/
.finance-modal-header {
    top: 20px;
    right: 20px;
}

.finance-document {
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 20px;
}

.signout-icon {
    visibility: visible;
    font-size: 20px;
    margin: 0 10px;
}

.signout-button {
    text-decoration: none;
    cursor: pointer;
    color: var(--teal);
}
.dropdown-menu {
    margin-left: -10px;
}

.navbar-toggler {
    border: none;
    padding-bottom: 30px;
}

.navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(28, 58, 64)' stroke-width='5' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    width: 36px;
    margin-left: 14px;
}

.navbar-toggler:focus {
    box-shadow: none !important;
}

.nav-brand {
    position: absolute;
    top: 0px;
    margin-left:34px;
}

.brand-div {
    align-items: flex-start;
    display: flex;
    margin-top: 25px;
    position: absolute;
}

.mobile-menu {
    position: absolute;
    top: 50px;
    width: 100%;
}

.dashboard-nav {
    padding-left: 8px;
}

.menu-pad {
    padding-left: 30rem !important;
}

.grid {
    display: grid;
}
.align-self-end, .a-s-e {
    align-self: end;
    align-self: flex-end;
}

/* Styling for aip and completions*/
.boxty {
    border-width: 1px;
    border-radius: 10px;
    padding: 20px 20px 20px 20px;
    min-height:245px;
    width: 270px;
}
.boxty.wide {
    width:unset;
}
.boxty.short {
    min-height:unset;
}

.m-3-notmobile {
    margin: 1rem;
}

.boxty.tall {
    min-height: 355px;
}

.boxty.rounder {
    border-radius: 25px;
}
/* Styling for aip and completions*/
.boxty.nomin {
    min-height:0px !important;
}
.boxty.borderless {
    border: none;
    padding: 0;
}

.boxty h1 {
    font-family: var(--secondary-font);
    font-weight: bold;
    font-style: normal;
    font-size: 24px;
    color: var(--teal);
    padding-bottom: 4px;
}

.boxty h2 {
    font-family: var(--secondary-font);
    font-weight: bold;
    font-style: normal;
    font-size: 20px;
    color: var(--teal);
    padding-bottom: 4px;
}

.boxty h3 {
    font-weight: 600;
}

.boxty p {
    font-family:var(--secondary-font);
    font-style: normal;
    font-size: 16px;
    margin-bottom: 6px;
}

.boxty p span {
    font-family:var(--secondary-font);
    font-weight:bold;
}

span.std {
    font-weight: normal !important;
}

.boxty .status {
    font-family: var(--secondary-font);
    text-align: center;
    font-weight: bold;
}

.box-green {
    border-style: solid;
    border-color:  var(--teal);
    background-color: var(--chartreuse);
}

.box-amber {
    border-style: solid;
    border-color:  rgb(254, 151, 43);
}

.box-red {
    border-style: solid;
    border-color:  #dc4405;
}

.box-red-fill {
    border-style: solid;
    border-color:  #dc4405;
    background-color: #dc4405;
}

.box-gray {
    border-style: solid;
    border-color: #aaaaaa;
}

.box-sage {
    border-style: solid;
    border-color:  var(--teal);
    background-color: var(--sage);
}

.box-teal {
    border-style: solid;
    border-color:  var(--teal);
    background-color: var(--teal);
}

.box-teal-outline {
    border-style: solid;
    border-color:  var(--teal);
}

.box-beam {
    border-style: solid;
    border-color: var(--teal);
    background-color: var(--beam-background);
    padding: 0;
    margin: 0;
    overflow: hidden;
    max-width: 750px;
    width: unset;
}
.box-beam p {
    font-size: 16px;
}

.box-travertine-outline {
    border-style: solid;
    border-color: var(--travertine);
}

.box-travertine {
    border-style: solid;
    border-color: var(--travertine);
    background-color: var(--travertine);
}


.box-error {
    border-style: solid;
    border-color: var(--error);
    background-color: var(--limestone);
}

.box-chartreuse {
    border-style: solid;
    border-color:  var(--teal);
    background-color: var(--chartreuse);
}

.minheight64 {
    min-height: 64px;
}

.minheight221 {
    min-height: 221px !important;
}

.minheight200 {
    min-height: 200px !important;
}

.text-green {
    color: #33cc99 !important;
}

.toggle-green {
    color: #33cc99 !important;
    background-color: #33cc99 !important;
}


.text-amber {
    color: #fe972b !important;
}

.text-red {
    color: rgb(255, 51, 0) !important;
}

.text-gray {
    color: #4A4A4A;

}

.text-white {
    color: white;

}

/*
    FILESTACK STYLES
*/

.fsp-picker--inline .fsp-picker-holder {
    width: 100% !important;
}

.fsp-picker--inline {
    min-width: 200px !important;
    z-index: 1;
}

.fsp-footer {
    height: 134px !important;
}

.fsp-button {
    padding: 0 20px;
    min-width: 140px;
    text-align: center;
}

.ob.overlay {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: visible;
    padding-bottom: 4%;
    padding-top: 2%;
    transition-delay: 0;
    transition-duration: 350ms;
    transition-property: opacity;
    transition-timing-function: ease-in-out,transform;
}

/* @media only screen and (max-width: 1399px) {
    .percentage-step {
        right: 70px;
    }
} */

/* @media only screen and (max-width: 1199px) {
    .percentage-step {
        right: 78px;
    }
} */

i {
    position: relative;
    z-index: 2;
}

.mobile-only {
    display: none;
}

@media only screen and (max-width: 1720px) {
}

@media only screen and (max-width: 1500px) {
    .pill-row {
        padding-left: 0;
        padding-right: 0;
    }
}

@media only screen and (max-width: 1200px) {
    .w-180 {
        width: 50%;
    }
    .lg-margin-top-2 {
        margin-top: 2rem;
    }
}

@media only screen and (max-width: 992px) {
    .xs-margin-top-3 {
        margin-top: 3rem;
    }
    .mortgage-detail-div {
        min-height: 0;
    }
    .mobile-only {
        display: block;
    }
    .desktop-only {
        display: none;
    }
    .pill-row {
        padding-left: 0;
        padding-right: 0;
    }
    .justify-center-mobile {
        justify-content: center;
        justify-items: center;
        text-align: center;
        align-items: center;
        align-content: center;
    }
    .boxty {
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .boxty.wide {
        width:270px;
    }
    
    .menu-pad {
        padding-left: 0px !important;
    }
    .nav-brand {
        position: absolute;
        top: 0px;
        margin:auto;
    }
    .navbar-brand {
        margin-left: 140px;
        position: relative;
        z-index: 1;
    }
    .navbar-toggler {
        z-index: 1;
    }
    .brand-div {
        display: block !important;
        text-align: center !important;
    }
    .doddl-nav {
        margin-top: -75px;
        width: 100%;
        height: auto;
        padding-left: 0;
        padding-right: 0;
        background-color: transparent;
    }
    .nav-link {
        margin-bottom: 0px;
        padding: 0 !important;
    }
    .sidenav {
        height: auto;
    }
    .applicantnav {
        width: 100%;
        margin-top: -10px;
    }
    .bump-right-profile {
        margin-left: 0 !important;
    }
    .full-width-x-margin {
        width: calc(100% - 100px)
    }
    .profile-group {
        padding-left: 10px;
        padding-right: 10px;
    }
    .applicantnav-submenu {
        margin-left: 0;
        margin-right: 0;
    }
    .bump-right,.bump-right-sm {
        margin-left: 0px;
        width: 100%;
        animation: none;
    }
    .navbar-collapse {
        width: 100%;
        z-index: 4;
        margin-top: -5px;
        padding-bottom: 5px;
        border-bottom: #eeeeee solid;
        border-right: none;
        background-color: var(--limestone);
    }
    .doddl-nav:has(div.navbar-collapse.show) {
        height: 100%;
    }
    .applicantnav {
        height: auto;
        background-color: white;
    }
    /* .percentage-step {
        right: 38px;
    } */
    .signout-button {
        padding: 5px 20px !important;
        margin: 55px 0;
        top: 6px !important;
        position: relative;
    }
    .pill-component {
        white-space: nowrap;
        overflow: hidden;
        -max-width: 220px;
        /* height: 40px; */
        /* line-height: 40px; */
        margin-top: -2px;
        padding-top: 0;
    }
    .pill-component input, .pill-component .ms-DatePicker {
        /* height: 40px; */
    }
    .page-title > h1 {
        font-size: 35px;
        font-family: var(--primary-font);
        color: var(--teal);
        font-weight: 900 !important;
        text-align: center;
    }
    .btn-wide {
        width: 100px !important;
    }
    .modal-content {
        height: auto !important;
    }
    .desktop {
        display: none;
    }
    .mobile {
        display: block;
    }
    .sub-title-box {
        padding-left: 10px;
        padding-right: 10px;

    }
    .sub-title-box.mobile {
        padding-bottom: 30px;
        animation: fadeIn 2s;
    }
    .beam-stack-breakpoint {
        width: 100%;
    }
    .timeline-container {
        visibility: hidden;
        box-shadow: 0 4px 2px -2px #dddddd;
        width: 100%;
        height: 100vh;
    }
    .timeline-collapsed-mobile {
        margin-top: -5px;
        margin-bottom: 20px;
        visibility: visible !important;
    }
    .timline-container-mobile {
        position: absolute !important;
        display: block;
        top: 80px !important;
        left: 0 !important;
        padding: 20px;
        background-color: white;
        visibility: visible !important;
        z-index: 20 !important;
    }
    .timline-container-mobile-collapsed {
        z-index: 0 !important;
        visibility: hidden !important;
    }
    .timeline button{
        position: absolute;
    }
    .portal-page {
        padding-top: 100px;
    }
    .calendly-container {
        min-width:350px;
        height: 800px;
    }
    .document-upload {
        border-right: none;
    }
    .ic-button {
        margin-top: 5px;
        margin-right: 30px;
        opacity: 0.5;
    }
}

@media only screen and (max-width: 768px) {

    nav:has(button.collapsed) { width: 0; }

    
    .popover {
        visibility: hidden !important;
    }
    .modal-footer {
        justify-content:center;
    }
    .modal-fullscreen-lg-down {
        height: calc(100vh-20px);
        align-items: unset;
    }
    /* For mobile phones: */
    .pill-stack {
        -justify-content: left;
    }
    .col {
        padding-right: 0;
    }
    .document-file > .row > .col-1 {
        width: 8% !important;
    }
    [class*="col-"]:not(.pt-col) {
      width: 100%;
      padding-right: 0;
    }
    .col-md-12 {
        padding-right: 0 !important;
    }
    .col-8 {
        width: 80% !important;
    }
    .col-10 {
        width: 70% !important;
    }
    .col-2 {
        width: 10% !important;
    }
    .col-5:not(.pt-col){
        width: 40% !important;
    }
    .applicantnav {
        left: 60px;
    }
    .full-width-x-margin {
        width: calc(100% - 60px)
    }
    .sidenav {
        width: 75px;
        border-right: none;
    }
    .btn-nav {
        font-size: 12px;
    }
    .nav-text {
        text-wrap: nowrap;
        z-index: 1;
    }
    .mh5 {
        font-family: var(--secondary-font);
        font-size: 1.1rem !important;
    }
    .h6, h6 {
        font-size: 0.8rem;
    }
    .ih5.impact {
        font-weight: 700 !important;
        line-height: 26px;
        white-space: nowrap;
    }
    .progress-tile-initials-large {
        font-size: 38px;
        margin-top: -74px;
        padding-bottom: 10px;
        text-align: center;
        margin-bottom: 30px;
        position: relative;
        z-index: 1;
        }
    h3.impact-s {
        font-weight: 700;
        font-size: 15px !important;
    }
    .percentage-step {
        font-size: 18px;
        width: 30px;
        height: 30px;
        padding: 3px 5px 0px 5px;
    }
    .subtext {
        font-size: 14px !important;
    }
    .boxless {
        margin-left: 3px !important;
    }
    .md-p-se-3 {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .boxty {
        margin: 0 auto 15px auto !important;
    }
    .m-3-notmobile {
        margin: unset;
    }
    .me-3-mobile {
        margin-right: 1rem;
    }
        
    .box {
        margin: 0 15px 15px 15px;
    }
    .subtitle {
        margin-top: 5px;
        font-size: 12px !important;
        font-style: italic;
    }
    .completions-checklist{
        visibility: visible !important;
    }
    .signout-button {
        font-size: 20px;
        display: contents;
    }
    .signout-icon {
        visibility: visible !important;
        font-size: 30px;
        margin-top: 4px;
    }
    .login-container  {
        float: right;
        position: static;
        z-index: 5;
        margin-top: 20px;
    }
    .dropdown-menu:has(.signout-button) {
        margin-left: -140px;
    }

    .pill-count-col {
        text-align: end;
    }

    .input-group-text {
        text-wrap: pretty;
    }

    button.btn-close {
        margin-top: 0px !important;
        margin-right: 0px !important;
    }

    .pill-container {
        max-width: 97vw;
    }

    .pill-missing-fw {
        min-width: 60vw;
    }

    .pill-header {
        padding-left: 0;
    }
    .pill-text {
        font-size: 15px;
    }
    .pill-icon {
        margin-right: -45px;
    }
    .pill-component:last-of-type:not(.pill-missing) {
        -padding-right: 0;
    }
    .pill-component:has(select) {
        padding-right: 0px !important;
    }
    .pill-component select {
        min-width: 0;
        padding-right: 15px;
    }
    .cta-text {
        font-size: 18px !important;
        padding-top: 4px;
    }
    .buttonpilllabel {
        visibility: hidden;
    }
    .modal-dialog {
        width: 100%;
        max-width: none;
        height: 100%;
        margin: 0;
    }
    
    .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }
    
    .modal-body {
        overflow-y: auto;
    }

    .ob-step1, .ob-step2, .ob-step3 {
        display: inline-block;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: visible;
        height: 300px;
        width: 240px;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        backdrop-filter: opacity(0.5);
    }
    .ob-step1 {
        background-image: url('images/ob-step1.png');
        margin-top:0px;
        margin-left: 0px;
        rotate: 0deg;
    }
    .ob-step2 {
        background-image: url('images/ob-step2.png');
    }
    .ob-step3 {
        background-image: url('images/ob-step3.png');
        margin-top: 0px;
        margin-right: 0px;
        rotate: 0deg;
    }
    .ob-step1-text {
        margin-bottom: 20px;
    }
    .ob-step2-text {
        margin-top: 0px;
        margin-bottom: 20px;
    }
    .ob-step3-text {
    }
    .btn.xl {
        font-size: 16px;
        padding: 10px
    }

    .date-popup-override {
        position: absolute;
    }
    /* date-popup-width-fix */
    .pill-component:last-of-type:has(.date-popup-override) {
        width: 120px;
    }
    
    .btn.bigger {    
        padding: 10px 20px;
    }
    
    .aib-tile,.boi-tile,.n26-tile,.ptsb-tile,.revolut-tile {
        background-repeat: no-repeat;
        background-size: 30px;
        height: 30px;
        width: 30px;
    }

    .box-block-80 {
        min-height: 0;
    }
    
    
}


.timeline-collapsed-mobile {
    visibility: hidden;
}
.timline-container-mobile {
    visibility: hidden;
}

/* @media only screen and (max-width: 499px) {
    .percentage-step {
        right: 10%;
    }
}*/


  
